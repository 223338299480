import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReduxAsyncConnect } from 'redux-connect';
import { MobileDebugOverlay } from 'src/client/MobileDebugOverlay';
import { isProdEnv } from 'src/common/utils/environment';
import GlobalErrorBoundary from '../common/components/errors/GlobalErrorBoundary';
import reloadOnPropsChange from './utils/reloadOnPropsChange';

export default function Root({
  apiClient,
  onHistoryUpdate,
  store,
  routes,
  numHotReloads,
  localizations,
  locale,
}) {
  const props = {
    apiClient,
    onHistoryUpdate,
    store,
    localizations,
    locale,
  };

  return (
    <Provider store={store}>
      {!isProdEnv() && <MobileDebugOverlay />}
      <GlobalErrorBoundary localizations={localizations}>
        <Router keyLength={numHotReloads}>
          <ReduxAsyncConnect
            routes={routes}
            render={() => renderRoutes(routes, props)}
            reloadOnPropsChange={reloadOnPropsChange}
          />
        </Router>
      </GlobalErrorBoundary>
    </Provider>
  );
}

Root.propTypes = {
  apiClient: PropTypes.object.isRequired,
  onHistoryUpdate: PropTypes.func.isRequired,
  store: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
  numHotReloads: PropTypes.number.isRequired,
  localizations: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};
