import classNames from 'clsx';
import { useEffect, useRef, useState } from 'react';
import useOnMount from 'src/common/hooks/useOnMount';
import { safeJsonStringify } from 'src/common/utils/object/safeJsonStringify';
import css from './MobileDebugOverlay.module.css';

export function MobileDebugOverlay() {
  const [isOverlayEnabled, setIsOverlayEnabled] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const overlayRef = useRef(null);

  useOnMount(() => {
    setIsOverlayEnabled(
      new URLSearchParams(location.search).get('debugOverlay') === 'true',
    );
  });

  useEffect(() => {
    const overlayEl = overlayRef.current;

    if (!(isOverlayEnabled && overlayEl)) {
      return;
    }

    let lastTap = 0;

    // double tap anywhere on the screen to show/hide the overlay
    document.addEventListener('touchend', () => {
      const currentTime = new Date().getTime();
      const tapLength = currentTime - lastTap;

      if (tapLength && tapLength < 300) {
        setIsOverlayVisible((prev) => !prev);
      }

      lastTap = currentTime;
    });

    const consoleLog = console.log;

    console.log = (...args) => {
      consoleLog(...args);

      try {
        overlayEl.innerHTML += `${args.map((arg) => (typeof arg === 'string' ? arg : safeJsonStringify(arg))).join(' ')}<br/>`;
      } catch {
        // Ignore
      }
    };
  }, [isOverlayEnabled]);

  return isOverlayEnabled ? (
    <div
      ref={overlayRef}
      className={classNames(css.overlay, { [css.visible]: isOverlayVisible })}
    />
  ) : null;
}
