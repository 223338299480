class ApiSingleton {
  #api;

  get instance() {
    return this.#api;
  }

  init(apiClient) {
    this.#api = apiClient;
  }
}

export const apiSingleton = new ApiSingleton();
