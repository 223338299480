import * as Sentry from '@sentry/browser';
import {
  sentryClientDsn,
  sentryConfig,
} from 'src/common/constants/analytics/sentry';
import shouldLogToSentry from '../../common/utils/shouldLogToSentry';
import vars from '../../common/vars';

if (shouldLogToSentry()) {
  const options = {
    ...sentryConfig,
    integrations: [
      Sentry.extraErrorDataIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.thirdPartyErrorFilterIntegration({
        // filterKeys array should only contain 1 value, which must match the applicationKey value passed to the Sentry webpack plugin
        filterKeys: [vars.get('SENTRY_CUSTOM_APP_ID')],

        // Defines how to handle errors that contain third party stack frames.
        // Possible values are:
        // - 'drop-error-if-contains-third-party-frames'
        // - 'drop-error-if-exclusively-contains-third-party-frames'
        // - 'apply-tag-if-contains-third-party-frames'
        // - 'apply-tag-if-exclusively-contains-third-party-frames'
        behaviour: 'drop-error-if-contains-third-party-frames',
      }),
    ],
    tracePropagationTargets: [
      /^https?:\/\/localhost/,
      new RegExp(`^https?://${vars.get('GEMINI_ORIGIN')}`),
      /^\//,
    ],
  };

  Sentry.init({
    dsn: sentryClientDsn,
    ...options,
  });
}
