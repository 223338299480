import oneTrust from '../../common/consent/OneTrust';
import { selectDesktopOs, selectIsFord } from '../../common/selectors/app';
import { getGoogleAnalyticsProperty } from '../../common/utils/analytics/getGoogleAnalyticsProperty';
import { apiSingleton } from '../api';
import { comscoreTrackPageview } from './comscore/comscoreUtils';
import { fbTrackPageView } from './facebook/fbTrack';
import { gtag } from './google/gaTracking';
import initComscore from './vendorScripts/initComscore';
import initFbTrack from './vendorScripts/initFbTrack';
import initGoogleConversion from './vendorScripts/initGoogleConversion';
import loadAmpBingTracking from './vendorScripts/loadAmpBingTracking';
import { createGlobalGTagFunction } from './vendorScripts/loadGTag';
import loadYahooPixelTracking from './vendorScripts/loadYahooPixelTracking';

function loadDependencies(
  location,
  googleAnalyticsPropertyId,
  isOptedOutOfPerformance,
  isOptedOutOfTargeting,
  gdprApplies,
) {
  createGlobalGTagFunction(googleAnalyticsPropertyId);
  initFbTrack(gdprApplies, isOptedOutOfTargeting);
  initGoogleConversion();
  loadAmpBingTracking();
  loadYahooPixelTracking();
  initComscore(isOptedOutOfPerformance);
}

const analyticsController = {
  config: {},
  stepNumber: 0,
  previousPath: null,

  sendPageView(path) {
    if (!path) {
      return;
    }

    apiSingleton.instance.reports
      ?.sendPageView({
        s: this.stepNumber,
        r: path,
      })
      .catch(() => {});

    this.previousPath = path;
    this.stepNumber++;
  },
};

export async function initClientAnalytics(store, history) {
  const state = store.getState();

  if (selectIsFord(state)) {
    return;
  }

  const googleAnalyticsPropertyId = getGoogleAnalyticsProperty(
    selectDesktopOs(state),
  );

  analyticsController.config = {
    store,
    history,
    googleAnalyticsPropertyId,
  };

  const [isOptedOutOfPerformance, isOptedOutOfTargeting, gdprApplies] =
    await Promise.all([
      oneTrust.checkIsOptedOutOfPerformance(),
      oneTrust.checkIsOptedOutOfTargeting(),
      oneTrust.checkGdprApplicability(),
    ]);

  loadDependencies(
    history.location,
    googleAnalyticsPropertyId,
    isOptedOutOfPerformance,
    isOptedOutOfTargeting,
    gdprApplies,
  );
  analyticsController.sendPageView(document.referrer);
  analyticsController.sendPageView(history.location.pathname);
}

export async function sendPageView(location) {
  const path = location.pathname || '';
  const { googleAnalyticsPropertyId } = analyticsController.config;
  const isOptedOutOfPerformance = await oneTrust.checkIsOptedOutOfPerformance();

  gtag('set', 'page', path);
  gtag('config', googleAnalyticsPropertyId);
  comscoreTrackPageview(isOptedOutOfPerformance);
  fbTrackPageView(path);

  if (path !== analyticsController.previousPath) {
    analyticsController.sendPageView(path);
  }
}
